.sideNav{
    background: linear-gradient(342deg, rgba(2,0,36,1) 0%, rgba(9,103,121,1) 47%, rgba(4,162,193,1) 70%, rgba(0,212,255,1) 100%);
    width: 15%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    
}



.iconB{
    margin: 10;
}

p{
    margin: 10px;
    font-size: 24px;
}


.navHeader{
    width: 100%;
    height: 200px;
    background-color: rgba(11, 27, 46, 0.39);
    margin: 0;
    margin-top: -20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 0px;
}
.navHeader h1{
    background-color: "white";
    background-image: none;
    color: white;
    text-align: center;

}