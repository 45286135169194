/* Remove margins and padding from the table, rows, and cells */
.headerCell {
  font-weight:bolder;
  /* font-size: medium; */
  background-color: #067996;
}
.customHeader .MuiDataGrid-colCellWrapper {
  background-color: #067996; /* Change the background color as desired */
}

table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 50px;
  }
  
  .tableStyle{
    background-color: white;
    width:160dvh;
    height: 49dvh;
    border-radius: 5px;;
    display: flex;
    margin: auto;
    margin-top: 50px;

    flex-direction: column;
    justify-content: flex-start;
  }


  th,
  td {
    padding: 8px; /* Adjust this value as needed */
  }
  
  /* Optional: Add borders for better separation between cells */
  th,
  td {
    border: 1px solid #ddd; /* Adjust border styles as needed */
  }
  
  /* Optional: Highlight the header row */
  thead {
    background-color: #f2f2f2; /* Adjust background color as needed */
  }
  