.datePicker {
  @apply  my-2 flex flex-col justify-start items-center  h-full;
}

.datePicker label {
  @apply text-xs font-semibold font-sans p-1 text-left  self-start;
}

.datePicker input {
  @apply w-full h-10 rounded-md border-0 bg-transparent ring-1 ring-gray-300 py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm;
}
