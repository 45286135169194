.header {
    /* display: flex; */
    justify-content: space-between; /* Aligns items at the start and end */
    padding: 10px;
    background-color: #f0f0f0;
  }
  
  .user-info {
    display: flex;
    align-items: center; /* Aligns items vertically */
    justify-content: flex-end; /* Aligns items at the end */
  }
  
  .user-info span {
    margin-right: 10px; /* Adds spacing between the username and logout button */
  }
  