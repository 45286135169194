.hospitalHome{
    background-color: white;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0; 
    left: 300;
}

.hospitalHome{
    background-color: lightgray;
    border: 1px black solid;
}

.hospitalHeader{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.h1{
    font-weight: bold;
    font-size: larger;
}


.tableContainer {
    margin: 0 auto; /* This centers the table container horizontally */
    max-width: fit-content; /* Adjusts container width based on content */
}

.hospitalBody{
    width: 85%;
    background-color: white;
    overflow: hidden;
    position: absolute;
    right: 0;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    text-align: flex-start;
}

.hospitalBody h3{
    padding: 15px;
    text-align: start;
}