.checkBox {
  width: 100%;

  height: fit-content;
 
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 10px;
}

.checkBox p {
  @apply font-sans text-center text-sm ml-3;
}

.checkBox input {
  padding: 0px;
  margin: 0px;
}

.checkBoxes h3 {
  @apply font-sans text-left text-black font-semibold text-sm mt-1 ml-1;
}

.checkBoxes {
  border-radius: 5px;
  width: 100%;
  margin: 0px;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
  border: 0.5px solid lightgray;
  overflow: hidden;
  justify-content: center;
  align-self: center;
  padding:5px;
  margin-top: 10px;
}

.checkBoxes .checkBox {
  position: relative;
  width: 93%;
  align-self: center;
}
