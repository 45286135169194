.loginScreen{
    background: linear-gradient(342deg, rgba(2,0,36,1) 0%, rgba(9,103,121,1) 47%, rgba(4,162,193,1) 70%, rgba(0,212,255,1) 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
}


.loginCard{
    background-color: white;
    width: 400px;
    height: 400px;
    border-radius: 5px;
    margin: 30px;
    box-shadow: 3px 4px 5px black;
    display: flex;
    flex-direction: column;
    padding: 5px;
    align-items: center;
}


h1{
    margin-bottom: 40px;
background: linear-gradient(342deg, rgba(2,0,36,1) 0%, rgba(9,103,121,1) 47%, rgba(4,162,193,1) 70%, rgba(0,212,255,1) 100%);
}

.loginButton{
    width: 80%;
    margin-top: 40px;
}

.Input{
    width: 80%;
    margin: 20px;
}

button{

}
